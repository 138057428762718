/*
 *  Ensure link paths are useable
 *
 *  Internal AEM paths are converted to front-end links,
 *  otherwise the url or path is returned unmodified.
 */
import env from 'env/env';

const AEM_PATH_PREFIX = '/content/waitrosegroceriescms/en/';
const AEM_CONTENT = 'wtr-content';

export default (path: string) => {
  let url = path;

  /*
    NOTE: This will transform any internal AEM paths into front-end paths, however the mapping isn't always 1-to-1.
    WPIP-56480 will explore this in more detail.
  */
  if (url.includes(AEM_PATH_PREFIX)) {
    const frontEndPath = url.replace(AEM_PATH_PREFIX, '').replace(AEM_CONTENT, 'content');

    url = `${env.root}/${frontEndPath}`;
  }

  return url;
};
