import React from 'react';
import { LinkProps } from 'react-router-dom';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';

import styles from 'components/VerticalTextLinks/index.scss';

export type VerticalLink = {
  label: string;
  component: 'a' | React.ComponentType<LinkProps>;
  href?: string;
  target?: string;
  to?: string;
};

type VerticalLinksProps = {
  links: VerticalLink[];
  maxLength?: number;
};

const VerticalTextLinks = ({ links, maxLength }: VerticalLinksProps) => {
  if (!links?.length) {
    return null;
  }

  let linkList = links;

  if (maxLength && linkList.length > maxLength) {
    linkList = linkList.slice(0, maxLength);
  }

  return (
    <div className={`${styles.container} vertical-text-links`} data-testid="vertical-text-links">
      {linkList.map(({ label, ...props }: VerticalLink) => (
        <TextLink {...props} className={styles.link} key={label}>
          {label}
        </TextLink>
      ))}
    </div>
  );
};

export default VerticalTextLinks;
