import React from 'react';
import { Link } from 'react-router-dom';
import {
  NavLinksContainer,
  NavLink,
} from '@johnlewispartnership/wtr-ingredients/ingredients/NavLinks';

import VerticalTextLinks, { VerticalLink } from 'components/VerticalTextLinks';
import transformAemPath from './cms-path-to-url';
import styles from './index.scss';

export type NavLinkProps = {
  url: string;
  text: string;
  newWindow: boolean;
};

export type NavigationLinksComponentProps = {
  componentId: string;
  navigationLinks: NavLinkProps[];
  styleIds: string[];
};

const NavLinks = ({ componentId, navigationLinks, styleIds }: NavigationLinksComponentProps) => {
  const isVerticalTextLinks = styleIds?.includes('vertical-text-links');

  if (!navigationLinks?.length) return null;

  const links: VerticalLink[] = navigationLinks.map(navLink => {
    const url = transformAemPath(navLink.url);

    return {
      label: navLink.text,
      ...(navLink.newWindow
        ? {
            component: 'a',
            target: '_blank',
            href: url,
          }
        : { component: Link, to: url }),
    };
  });

  if (isVerticalTextLinks) {
    return <VerticalTextLinks links={links} />;
  }

  return (
    <div className={styles.before}>
      <NavLinksContainer
        linkElement="a"
        id={componentId}
        backgroundColor="scallop"
        data-testid="nav-links"
      >
        {links.map(({ label, ...linkProps }) => (
          <NavLink {...linkProps} key={label}>
            {label}
          </NavLink>
        ))}
      </NavLinksContainer>
    </div>
  );
};

export default NavLinks;
